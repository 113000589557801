import { Component, OnInit } from '@angular/core';
import { CompanyListStore } from './company-list.store';
import { map } from 'rxjs';
import { Router } from '@angular/router';
import { CreateCompanyDialog, CreateCompanyDialogData } from '../create/create.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Company } from 'src/app/services/bdoservice/companies/models/company.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  providers: [ CompanyListStore ],
})
export class ListComponent implements OnInit {

  page$ = this.listStore.page$;
  companies$ = this.page$.pipe(map(page => page?.content));

  loading$ = this.listStore.loading$;
  lastLazyLoadEvent: any;
  searchText = '';
  searchMode = false;

  constructor(
    private listStore: CompanyListStore,
    private router: Router,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {

  }

  search(event: any) {
    this.searchMode = true;
    const sortBy = this.lastLazyLoadEvent ? `${this.lastLazyLoadEvent.sortField},${this.lastLazyLoadEvent.sortOrder === 1 ? 'asc' : 'desc'}` : '';
    this.listStore.getPage(
      { page: 0, pageSize: 10, query: `name==*${this.searchText}*`, sortBy: sortBy}
    );
  }

  clearSearch() {
    this.searchText = '';
    this.searchMode = false;
    this.listStore.getPage(
      { page: 0, pageSize: 10, query: '', sortBy: ''}
    );
  }

  onLazyLoad(event: any) {
    this.lastLazyLoadEvent = event;
    const sortType = event.sortOrder === 1 ? 'asc' : 'desc';
    //generate rsql sortBy
    const sortBy = event.sortField ? `${event.sortField},${sortType}` : '';
    this.listStore.getPage(
      { page: event.first / event.rows, pageSize: event.rows, query: this.searchText, sortBy: sortBy}
    );
  }


  showCreateDialog() {
    const ref = this.dialogService.open(CreateCompanyDialog, {
      data: new CreateCompanyDialogData(
        'create',
        (company: Company) => {
          this.onLazyLoad(this.lastLazyLoadEvent);
        }
      ),
      header: 'Dodaj podmiot',
      width: '50%',
      contentStyle: { 'max-height': '500px', 'overflow': 'auto' }
    });
  }

  routeToDetails(companyId: string) {
    this.router.navigate([`/dashboard/companies/${companyId}/details`]);
  }

}
