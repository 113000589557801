import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { ColumnRow, GenericTableComponent, TableConfig } from '@mobiletract/ngx-generic-table';
import { Store } from '@ngrx/store';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { selectCompanies } from 'src/app/common/ui/company-context-select/company-context.selectors';
import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';
import { CompanyUnit } from 'src/app/services/bdoservice/companies/models/company-unit.model';
import { CreateCompanyDialog, CreateCompanyDialogData } from '../create/create.component';
import { on } from 'events';
import { loadCompanies } from 'src/app/common/ui/company-context-select/company-context.actions';
import { Company } from 'src/app/services/bdoservice/companies/models/company.model';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-company-details-list',
  templateUrl: './details.component.html',
  providers: [Table, TableService, ConfirmationService, MessageService]
})
export class CompanyDetailsComponent {
  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private store: Store
  ) {

  }

  @ViewChild('table') table: GenericTableComponent;
  lastLazyLoadEvent: LazyLoadEvent;

  subTitle = '';

  companyId: string = null;

  tableConfig: TableConfig = {
    paginator: true,
    rows: 10,
    loading: false,
    totalRecords: 0,
    filterable: false,
    first: 0,
    columnResizeMode: 'expand',
    responsiveLayout: 'stack',
    scrollable: true,
    tableMenuItems: [],
  };

  columns: ColumnRow[] = [];

  //PAir set headquarters id with boolean
  setHeadquartersLoading = false;
  syncUnitsLoading = false;

  deleteLoading = false;


  dataProvider = async (event: LazyLoadEvent) => {
    try {
      if(!this.companyId) return Promise.resolve({ data: [], totalRecords: 0 });

      this.lastLazyLoadEvent = event;

      if (!this.companyId) {
        return Promise.resolve({
          data: [],
          totalRecords: 0
        });
      }

      const response = await this.companyService.getCompanyUnits(this.companyId, false, event.first, event.rows);

      return Promise.resolve({
        data: response.content,
        totalRecords: response.totalElements
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if(params.id) {
        this.companyId = params.id;
        this.initColumns();
        this.store.select(selectCompanies).subscribe(companies => {
          const companyName =  companies.find(company => company.company.id === this.companyId)?.company.name;
          this.subTitle = `Podmiot: ${companyName}`;
        });

      }
    });
  }

  initColumns() {
    this.columns = [
      {
        columns: [
          {
            field: "id",
            type: 'text',
            load: (entry: CompanyUnit) => entry.name,
            header: "Nazwa",
            sortable: {
              enabled: true,
              field: 'id'
            }
          },
          {
            field: "isHeadquarters",
            type: 'boolean',
            load: (entry: CompanyUnit) => entry.isHeadquarters,
            header: "Siedziba",
            sortable: {
              enabled: true,
              field: 'isHeadquarters'
            }
          },
          // is active
          {
            field: "isActive",
            type: 'boolean',
            load: (entry: CompanyUnit) => entry.attributes.bdo_is_active,
            header: "Czy aktywne?",
          },
          //Wojewodztwo
          {
            field: "voivodeship",
            type: 'text',
            load: (entry: CompanyUnit) => entry.address.voivodeship,
            header: "Województwo",
            sortable: {
              enabled: true,
              field: 'voivodeship'
            }
          },
          //Gmina
          {
            field: "community",
            type: 'text',
            load: (entry: CompanyUnit) => entry.address.commune,
            header: "Gmina",
            sortable: {
              enabled: true,
              field: 'community'
            }
          },
          {
            field: "city",
            type: 'text',
            load: (entry: CompanyUnit) => entry.address.city,
            header: "Miasto",
            sortable: {
              enabled: true,
              field: 'city'
            }
          },
          {
            field: "street",
            type: 'text',
            load: (entry: CompanyUnit) => entry.address.street,
            header: "Ulica",
            sortable: {
              enabled: true,
              field: 'street'
            }
          },
        ],
      }];
  }

  setHeadquarters(companyUnit: CompanyUnit) {
    this.setHeadquartersLoading = true;

    this.companyService.setHeadquarters(this.companyId, companyUnit.id).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Siedziba została ustawiona' });
      this.table.loadLazy(this.lastLazyLoadEvent);
    })
    .catch((error) => {
      this.messageService.add({ severity: 'error', summary: 'Błąd', detail: error.message });
    })
    .finally(() => {
      this.setHeadquartersLoading = false;
    });
  }

  syncUnits() {
    this.syncUnitsLoading = true;

    this.companyService.syncCompany(this.companyId).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Jednostki zostały zsynchronizowane' });
      this.table.loadLazy(this.lastLazyLoadEvent);
    })
    .catch((error) => {
      this.messageService.add({ severity: 'error', summary: 'Błąd', detail: error.message });
    })
    .finally(() => {
      this.syncUnitsLoading = false;
    });
  }

  showDeleteDialog(event: Event) {
    const message = `Czy na pewno chcesz usunąć podmiot?`;

    this.confirmationService.confirm({
      target: event.target,
      icon: 'pi pi-exclamation-triangle',
      message: message,
      accept: () => {
        this.deleteLoading = true;
        this.companyService.deleteCompany(this.companyId).then(() => {
          this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Podmiot został usunięty.' });
          this.router.navigate(['/dashboard/companies']);
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Błąd', detail: err.message || 'Wystąpił nieoczekiwany błąd.' });
        }).finally(() => {
          this.deleteLoading = false;
        });
      }
    });
  }

  showEditDialog() {
    this.companyService.getCompany(this.companyId).then(company => {
      this.dialogService.open(CreateCompanyDialog, {
        data: new CreateCompanyDialogData(
          'edit',
          null,
          (company: Company) => {
            this.store.dispatch(loadCompanies());
          },
          company
        ),
        header: 'Edytuj podmiot',
        width: '50%',
        contentStyle: { 'max-height': '500px', 'overflow': 'auto' }
      });
    });
  }
}