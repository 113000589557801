<!-- <p-confirmPopup></p-confirmPopup> -->
<app-header-title title="Lista podmiotów">
  <ng-template #actionButtonsTemplate>
    <div class="flex items-center gap-2">
      <span class="p-input-icon-right">
        <i class="pi pi-search" *ngIf="!listLoading && !searchMode" pTooltip="Szukaj" (click)="search" (keyup.enter)="search()" tabindex="1"></i>
        <i class="pi pi-spin pi-spinner" *ngIf="loading$ | async"></i>
        <!-- x icon clear -->
        <i class="pi pi-times" *ngIf="searchMode" (click)="clearSearch()" pTooltip="Wyczyść" (keyup.enter)="clearSearch()" tabindex="2"></i>
       <input [disabled]="listLoading" pInputText type="text" placeholder="Szukaj" class="border border-gray-300 rounded-md p-3" [(ngModel)]="searchText" (keyup.enter)="search()" tabindex="0"/>
      </span>
      <p-button label="Dodaj podmiot" icon="pi pi-plus" (onClick)="showCreateDialog()"></p-button>
    </div>
  </ng-template>
</app-header-title>
<div class="flex flex-wrap gap-2 bg-white dark:bg-gray-800 rounded-xl mt-4 p-2 py-4 shadow-md mx-2">
  <p-table [value]="companies$ | async" class="mb-4 z-index-20 w-full"
  [paginator]="true"
  [rows]="(page$ | async)?.size"
  [totalRecords]="(page$ | async)?.totalElements"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [rows]="15"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-striped p-datatable-gridlines"
  responsiveLayout="stack"
  [breakpoint]="'960px'"
  [loading]="loading$ | async"
  sortField="name"
  [sortOrder]="1"

  >
    <ng-template pTemplate="header">
      <tr>
          <th pSortableColumn="name" scope="col">Nazwa <p-sortIcon field="name"></p-sortIcon></th>
          <th pSortableColumn="nip" scope="col">NIP <p-sortIcon field="nip"></p-sortIcon></th>
          <th pSortableColumn="bdoRegistrationNumber" scope="col">Numer rejestracyjny BDO <p-sortIcon field="bdoRegistrationNumber"></p-sortIcon></th>
          <th scope="col">Status</th>
          <th scope="col">Akcje</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-company>
      <tr>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">Nazwa</span>
            <div class="flex flex-row items-center gap-2">
              <img
              src="https://ui-avatars.com/api/?size=128&background=random&name={{
                company?.name
              }}"
              style="width: 18px"
              [alt]="company?.name"
            />
            <p>
              {{ company.name }}
            </p>
            </div>
            </td>
          <td>
            <span class="p-column-title font-semibold">NIP</span>
            {{ company.nip }}
          </td>
          <td>
            <span class="p-column-title font-semibold">Numer rejestracyjny BDO</span>
            {{ company.bdoRegistrationNumber }}
          </td>
          <td>
            <!-- Synchronizacja BDO checkbox green red-->
            <span *ngIf="company.bdoCompanyId">
              <i  class="pi pi-check-circle text-green-500"></i>
              Zsynchronizowany z BDO
            </span>
            <span *ngIf="!company.bdoCompanyId">
              <i  class="pi pi-times-circle text-red-500"></i>
              Nie zsynchronizowany z BDO
            </span>
          </td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <div class="flex flex-row gap-2">
              <p-button label="Szczegóły" icon="pi pi-check-circle" (onClick)="routeToDetails(company.id)"></p-button>
            </div>
          </td>
      </tr>
  </ng-template>
  </p-table>
</div>
