<div>
  <p>
    Za pomocą poniższego formularza możesz wprowadzić dane do podmiotu, którym
    chcesz zarządzać z poziomu aplikacji.
    <br/><br/>
  </p>
  <p>
    Jeżeli masz trudności z integracją, możesz skorzystać z instrukcji lub obejrzeć film instruktażowy.<br/><br/> W przypadku problemów z integracją, prosimy o kontakt z naszym działem wsparcia.
  </p>
   <form [formGroup]="form" class="flex flex-col gap-2">
    <p class="flex flex-col gap-2">
      <label for="name">Nazwa</label>
      <input type="text" pInputText formControlName="name" [required]="form.controls['nameFromBdo'].value === false" [disabled]="form.controls['nameFromBdo'].value === true"/>
      <small class="text-red-500" *ngIf="!form.controls['name'].valid && form.controls['name'].touched">{{ getError('name') }}</small>
    </p>
    <!-- nameFromBdo boolean value -->
    <p class="flex gap-2">
      <p-checkbox formControlName="nameFromBdo" binary="true" (onChange)="onChangeNameFromBdo($event)" ></p-checkbox>
      <label for="nameFromBdo">Pobierz nazwę z systemu ministerialnego BDO</label>
    </p>
    <p *ngIf="config.data.mode === 'create'" class="flex flex-col gap-2">
      <label for="clientId">Client Id</label>
      <input type="text" pInputText formControlName="clientId" required/>
      <small class="text-red-500" *ngIf="!form.controls['clientId'].valid && form.controls['clientId'].touched">{{ getError('clientId') }}</small>
    </p>
    <p *ngIf="config.data.mode === 'create'" class="flex flex-col gap-2">
      <label for="clientSecret">Client Secret</label>
      <input type="text" pInputText formControlName="clientSecret" required/>
      <small class="text-red-500" *ngIf="!form.controls['clientSecret'].valid && form.controls['clientSecret'].touched">{{ getError('clientSecret') }}</small>
    </p>
    <!-- Chip group dodania aliasów -->
    <small class="text-red-500" *ngIf="formError">{{ formError }}</small>
  </form>
</div>
<div class="mt-4 flex gap-2">
  <p-button label="Wróć" [text]="true" (onClick)="onNoClick()"></p-button>
  <p-button label="Zapisz" (onClick)="saveForm()" [disabled]="!form.valid"></p-button>
</div>
