<p-confirmPopup></p-confirmPopup>
<app-header-title title="Szczegóły podmiotu" [subtitle]="subTitle">
    <ng-template #actionButtonsTemplate>
        <div class="flex items center gap-2">
          <p-button label="Synchronizuj" icon="pi pi-plus" (onClick)="syncUnits()" [loading]="syncUnitsLoading"></p-button>
            <p-button label="Edytuj" icon="pi pi-plus" (onClick)="showEditDialog($event)"></p-button>
            <p-button label="Usuń" severity="danger" icon="pi pi-trash" (onClick)="showDeleteDialog($event)" [loading]="deleteLoading"></p-button>
        </div>
    </ng-template>
</app-header-title>

<div class="flex flex-col gap-2 mx-2 mb-8 p-2 z-index-40 bg-white dark:bg-gray-800 rounded-xl shadow-md">
    <p-tabView>
      <p-tabPanel header="Dane firmy">
        <ng-template pTemplate="content">
          <app-company-info [companyId]="companyId"></app-company-info>
          <h3 class="text-lg font-bold mb-4">Miejsca prowadzenia działalności</h3>
          <app-generic-table
            #table
            styleClass="p-datatable-gridlines" 
            [columnRows]="columns"
            [dataProvider]="dataProvider"
            [tableConfig]="tableConfig">
          
            <ng-template #rowActionsTemplate let-rowData let-editing="editing">
                <p-button *ngIf="!editing || rowData.isHeadquarters" label="Ustaw jako siedziba" icon="pi pi-home" (onClick)="setHeadquarters(rowData)" [loading]="setHeadquartersLoading"></p-button>
            </ng-template>

          </app-generic-table>
        </ng-template>
        <ng-template pTemplate="header">
          <div class="flex gap-2 items-center">
            <img src="../../../../assets/icons/company/company-info.png" alt="Dane firmy" class="w-8 h-8">
            <h3 class="text-md text-black font-semibold">Dane firmy</h3>
          </div>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Klucze dostępu">
        <ng-template pTemplate="content">
          <app-credentials [companyId]="companyId"></app-credentials>
        </ng-template>
        <ng-template pTemplate="header">
          <div class="flex gap-2 items-center">
            <img src="../../../../assets/icons/company/credentials-list.png" alt="Klucze dostępu" class="w-8 h-8">
            <h3 class="text-md text-black font-semibold">Klucze dostępu</h3>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
  
  